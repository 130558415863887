import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ComputerIcon from '@mui/icons-material/Computer';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import EmailSharpIcon from '@mui/icons-material/EmailSharp';
import Divider from '@mui/material/Divider';
import SimCardDownloadSharpIcon from '@mui/icons-material/SimCardDownloadSharp';
//import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/*function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Derek Miller-Young 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
} */

const cards = [
  {name:'ROGA Drone', img: 'https://firebasestorage.googleapis.com/v0/b/derekwebsite-9a9e7.appspot.com/o/images%2Frogo.png?alt=media&token=8eef65c4-5203-425e-9b5e-f75a80d7c3df', url: 'https://rogadrone.com/', desc:'Website & Drone Simulator App'},
  {name:'Gee Tee Holdings', img: 'https://firebasestorage.googleapis.com/v0/b/derekwebsite-9a9e7.appspot.com/o/images%2Ficon.png?alt=media&token=23bdc6ef-5390-4013-ba72-3655fad73aab', url: 'https://geeteeholdings.com/', desc:'Shop Website, Dashboard Web App & Mobile App'},
];

const theme = createTheme();

export default function Album() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="fixed" style={{backgroundColor:'#a80030'}}>
        <Toolbar >
          <ComputerIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Derek Miller-Young
          </Typography>
        </Toolbar>
      </AppBar>
      <main style={{marginTop:'40px', backgroundColor:'#1d1d26', color:'#fff'}}>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: '#222233',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              style={{color:'#ddd'}}
              gutterBottom
            >
              Developer
            </Typography>
            <Typography variant="h5" align="center" paragraph style={{color:'#ddd'}}>
            Graduate of Red River Polytech's Business Information Technology Program.
            </Typography>
            <Typography variant="h5" align="center" style={{color:'#ddd'}}>
            Web | Mobile | Software | Game | Database
            </Typography>
            <Divider></Divider>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button href={"mailto:derekmilleryoung@gmail.com"} startIcon={<EmailSharpIcon/>} variant="contained" style={{borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px',  borderTopRightRadius: '22px', backgroundImage: 'linear-gradient(to left, #571038, #680d3a, #79093b, #890539, #990436, #a50534, #b10832, #bd0f2e, #ca132d, #d7182b, #e41e29, #f02525)'}}>Contact Me</Button>
              <Button href={"https://firebasestorage.googleapis.com/v0/b/derekwebsite-9a9e7.appspot.com/o/images%2FDerekMillerYoung-Resume.pdf?alt=media&token=64edc492-2b9f-482a-bb58-9c4f5113a211"} endIcon={<SimCardDownloadSharpIcon/>} variant="contained" style={{borderBottomRightRadius: '0px',  borderBottomLeftRadius: '0px', borderTopLeftRadius: '22px', backgroundImage: 'linear-gradient(to right, #571038, #680d3a, #79093b, #890539, #990436, #a50534, #b10832, #bd0f2e, #ca132d, #d7182b, #e41e29, #f02525)'}}>View Resume</Button>
            </Stack>
          </Container>
          
        </Box>
        
        <Container sx={{ py: 8 }} maxWidth="md">
          
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card.name} xs={12} sm={6} md={4}>
                <Card style={{height:'100%', 
                display:'flex', justiyContent:'space-between', flexDirection:'column'}}
                  
                >
                  <CardMedia 
                    component="img"
                    style={{width:'300px',height:'200px',backgroundColor:'#fff'}}
                    image={card.img}
                    alt={card.name + ' image'}
                  />
                  <CardContent >
                    <Typography gutterBottom variant="h6" component="h2">
                      {card.name}
                    </Typography>
                    <Typography >
                      {card.desc}
                    </Typography>
                  </CardContent>
                  <CardActions  >
                    <Button size="small" href={card.url}>Visit</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: '#a80030', p: 6 }} component="footer">
     
      <Typography variant="h6" align="center">
          Derek Miller-Young
        </Typography>
        <Divider></Divider>
        <Typography
          variant="subtitle1"
          align="center"
          style={{color:'#ddd'}}
          component="p"
        >
          <a href="mailto:derekmilleryoung@gmail.com" >Contact</a> | <a href="https://firebasestorage.googleapis.com/v0/b/derekwebsite-9a9e7.appspot.com/o/images%2FDerekMillerYoung-Resume.pdf?alt=media&token=64edc492-2b9f-482a-bb58-9c4f5113a211"
           >Resume</a>
        </Typography>
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}