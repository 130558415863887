import React from 'react';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Home from './containers/Home';
import Album from './components/Album';
//import Header from './components/Navigation/AppBar';
//import Application from './Application';
//import {
//  Routes,
//  Route,
//} from "react-router-dom";

function App() {
  const { colorMode, setColorMode } = 'light';
  React.useEffect(() => {
    
  }, [colorMode]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
   
     
        
      <Album colorMode={colorMode} setColorMode={setColorMode}/>
       
       
  
    </React.Fragment>
  );
}

export default App;
